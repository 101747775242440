import Swiper from 'swiper';

export const tabs = () => {
    let tabBtn = document.querySelectorAll('[data-tab]'),
        tabBlock = document.querySelectorAll('[data-tablist]');

    tabBtn.forEach( (item)=> {
        item.addEventListener('click', () => {
            tabBtn.forEach( (btn)=> {
                btn.classList.remove('is-active');
            });
            item.classList.add('is-active');

            let name = item.dataset.tab,
                block = document.querySelector(`[data-tablist="${name}"]`);
            tabBlock.forEach( (elem) => {
                elem.classList.remove('is-active');
            });
            block.classList.add('is-active');
        });
    });

    if (window.innerWidth  < 671) {

        let tabsSlider = new Swiper('.tabs-slider', {
            containerModifierClass: 'tabs-slider',
            wrapperClass: 'tabs-slider__wrapper',
            slideClass: 'tabs-slide',
            width: 112,
        });
    }
}
