import Swiper from 'swiper';

export const aboutSlider = () => {
    let aboutSlider

    function initSlider() {
        if (aboutSlider !== undefined && window.innerWidth >= 671)  {
            aboutSlider.destroy()
            aboutSlider = undefined
        } else if (aboutSlider === undefined && window.innerWidth < 671){          
            aboutSlider = new Swiper('.about-slider', {
                containerModifierClass: 'about-slider',
                wrapperClass: 'about-slider__wrapper',
                slideClass: 'about-slide',
                slideActiveClass: 'about-slide__active',
                pagination: {
                    el: '.about-slider__pagination',
                    type: 'bullets',
                    bulletClass: 'about-slider__bullet',
                    bulletActiveClass: 'about-slider__bullet_active',
                    clickable: true
                },
            });
        }      
    }

    initSlider()
    window.addEventListener('resize', initSlider)
}