export const burger = () => {
    let burgerBtn = document.querySelector('[am-block="burger"]'),
        citiesBlock = document.querySelector('[am-cities="burger"]'),
        burgerBlock = document.querySelector('[am-section="burger"]');
    burgerBtn.addEventListener('click', () => {
        if (citiesBlock.classList.contains('is-active')) {
            citiesBlock.classList.remove('is-active');
        }
        burgerBtn.classList.toggle('is-active');
        burgerBlock.classList.toggle('is-active');
    });
}