export const contacts = () => {
    const block = document.querySelector('.js-contactsCityChangeBlock');

    if (block) {
        const btn = document.querySelector('.js-contactsCityChange');
        const close = document.querySelector('[am-close]');
        const back = document.querySelector('[am-back]');

        btn.addEventListener('click', function() {
            block.classList.add('is-active');
        });

        back.addEventListener('click', function() {
            block.classList.remove('is-active');
        });

        close.addEventListener('click', function() {
            block.classList.remove('is-active');
        });
    }
}
