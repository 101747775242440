import Swiper from 'swiper';

export const noOfficeSlider = () => {

    const sliderPagination = document.querySelector('[am-slider-pagination="no_office"]');

    if (sliderPagination) {
        const sliderPaginationElem = sliderPagination.querySelectorAll('[am-num]');
        let noOfficeSlider;

        function pagination(index) {
            sliderPaginationElem.forEach( function(item) {
                item.classList.remove('is-active');
            });

            for (let i = 0; i <= index; i++) {
                sliderPaginationElem[i].classList.add('is-active');
            }
        }

        function initSlider() {
            if (noOfficeSlider !== undefined && window.innerWidth >= 940)  {
                noOfficeSlider.destroy()
                noOfficeSlider = undefined
            } else if (noOfficeSlider === undefined && window.innerWidth < 940){          
                noOfficeSlider = new Swiper('.no_office-slider', {
                    containerModifierClass: 'no_office-slider',
                    wrapperClass: 'no_office-slider__wrapper',
                    slideClass: 'no_office-slide',
                    slideActiveClass: 'no_office-slide__active'
                });
    
                noOfficeSlider.on('slideChange', function() {
                    let index = this.realIndex;
                    pagination(index);
                });
    
                sliderPaginationElem.forEach( function(item) {
                    item.addEventListener('click', function() {
                        let index = item.textContent - 1;
                        noOfficeSlider.slideTo(index);
                        pagination(index);
                    });
                })
            }      
        }

        initSlider()
        window.addEventListener('resize', initSlider)
    }

}