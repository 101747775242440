export const map = () => {

    const creatMap = (parent) => {
        const map = document.querySelector(parent);
        const lat = map.dataset.latitude  || 60.58815;
        const lng = map.dataset.longitude || 56.832432;
        const hint = map.dataset.hint;
        const zoom = map.dataset.zoom || 12;
        loadScript('//api-maps.yandex.ru/2.1.73/?lang=ru_RU').then(function() {
            ymaps.ready(function() {
                const ymap = new ymaps.Map(map, {
                    center: [lat, lng],
                    zoom: zoom,
                    controls: [],
                    behaviors: ['multiTouch', 'drag']
                }, {
                    searchControlProvider: 'yandex#search'
                });

                ymap.controls.add('zoomControl', {
                    size: 'large'
                });

                const placemark = new ymaps.Placemark([lat, lng], {
                    balloonContent: hint,
                    hintContent: hint,
                }, {
                    preset: 'islands#redDotIcon',
                    iconLayout: 'default#image',
                    iconImageHref: '/app/assets/img/baloon.svg',
                    iconImageSize: [26, 36],
                    iconImageOffset: [-12, -36],
                });

                ymap.geoObjects.add(placemark);
            });
        });
    };

    let priceRect = document.querySelector('[am-section="price"]');

    if (priceRect) {

        const addMap = () => {
            priceRect = document.querySelector('[am-section="price"]').getBoundingClientRect().top;

            if (priceRect < window.innerHeight + window.pageYOffset) {
                creatMap('[am-map]');
                window.removeEventListener('scroll', addMap)
            }
        };

        if (window.innerWidth > 670) {
            window.addEventListener('scroll', addMap);
            addMap();
        };

    }

    let mapOpenBtn = document.querySelector('[data-open="map"');

    const mobMap = () => {
        creatMap('[am-place="map"]');
        mapOpenBtn.removeEventListener('click', mobMap);
    };

    if (mapOpenBtn) {
        mapOpenBtn.addEventListener('click', mobMap);
    }
}

/**
 * Асинхронная загрузка сторонних скриптов
 * @param  {string} url Ссылка на js скрипт
 */
function loadScript(url) {
    return new Promise(function (resolve, reject) {
        let r = false;
        let t = document.getElementsByTagName('script')[0];
        let s = document.createElement('script');

        s.type = 'text/javascript';
        s.src = url;
        s.async = true;
        s.onload = s.onreadystatechange = function () {
            if (!r && (!this.readyState || this.readyState === 'complete')) {
                r = true;
                resolve(this);
            }
        };
        s.onerror = s.onabort = reject;
        t.parentNode.insertBefore(s, t);
    });
}
