import { burger } from './components/burger';
import { city } from './components/city';
import { map } from './components/map';
import { modal } from './components/modal';
import { scrollTo } from './components/scroll_to';
import { offerSlider } from './components/offer_slider';
import { tabs } from './components/tabs';
import { noOfficeSlider } from './components/no_office_slider';
import { aboutSlider } from './components/about_slider';
import { contacts } from './components/contacts';

document.addEventListener('DOMContentLoaded', function () {
    burger();
    city();
    map();
    modal();
    scrollTo();
    offerSlider();
    tabs();
    noOfficeSlider();
    aboutSlider();
    contacts();

    // отправка события нажатия на ссылку whatsapp в метрику
    const $whatsappLinks = document.querySelectorAll('a[href*="https://wa.me/"]');
    if ($whatsappLinks && $whatsappLinks.length) {
        $whatsappLinks.forEach(function ($whatsapp) {
            $whatsapp.addEventListener('click', function(e) {
                if (typeof gtag == 'function') {
                    gtag('event', 'click', {
                        event_category: 'callback',
                    });
                }
            });
        });
    }
})

