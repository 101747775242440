import {getCookie, setCookie} from './cookie'

export const city = () => {
    let currentCity = document.querySelector('[data-current-city]');

    if (!getCookie('city')) {
        setCookie('city', currentCity.textContent, {domain: 'xn----7sba3alkubanxf.xn--p1ai', path: '/'});
    }

    const cityDesk = () => {

        let citySelect = document.querySelector('[am-city="header"]'),
            cityCurrent = document.querySelector('[data-current-city]'),
            cities= document.querySelectorAll('[data-drop-city]');

        citySelect.addEventListener('mouseenter', () => {
            citySelect.classList.add('is-active');
        });

        citySelect.addEventListener('mouseleave', () => {
            citySelect.classList.remove('is-active');
        });

        let city;

        cities.forEach( (item) => {
            item.addEventListener('click', () => {
                city = cityCurrent.textContent;
                cityCurrent.textContent = item.textContent;
                setCity(item.textContent);
                if (item.dataset.dropCity != 'contacts') {
                    item.textContent = city;
                }
            });
        });
    }

    cityDesk();

    // mob

    const cityMob = () => {

        let cityBtn = document.querySelector('[am-city="burger"]'),
            citiesBlock = document.querySelector('[am-cities="burger"]'),
            citiesItem = document.querySelectorAll('[am-cities-item="burger"]'),
            citiesClose = document.querySelector('[am-cities-close="burger"]');
        cityBtn.addEventListener('click', () => {
            citiesBlock.classList.add('is-active');
        });

        citiesClose.addEventListener('click', () => {
            citiesBlock.classList.remove('is-active');
        });

        citiesItem.forEach( (item) => {
            item.addEventListener('click', () => {
                citiesItem.forEach( (city) => {
                    city.classList.remove('is-active');
                });
                item.classList.add('is-active');

                // if (item.dataset.dropCity != 'contacts') {
                    cityBtn.textContent = item.textContent;
                // }

                setTimeout( () => {
                    citiesBlock.classList.remove('is-active');
                    setCity(item.textContent);
                },300)
            });
        });
    }

    cityMob();

    function setCity(city) {
        setCookie('city', city.trim(), {domain: 'xn----7sba3alkubanxf.xn--p1ai', path: '/'});
        window.location.reload();
    }
}
