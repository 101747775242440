export const scrollTo = () => {
    let navItem = document.querySelectorAll('[data-target]');
    navItem.forEach( (item) => {
        item.addEventListener('click', () => {
            let name = item.dataset.target,
                top = document.documentElement.scrollTop || document.body.scrollTop,
                elem = document.querySelector(`[am-section="${name}"]`);

            if (elem) {
                let target = elem.getBoundingClientRect().top + top;

                if (target > top && !(target == top + 10 || target < top + 10)) {

                    let anim = setInterval( () => {
                        window.scrollTo(0,top + 10);
                        top += 10;

                        if (top >= target) {
                            clearInterval(anim);
                        };
                    },1);

                } else if (target < top && !(target == top - 10 || target > top + 10)) {

                    let anim = setInterval( () => {
                        window.scrollTo(0,top - 10);
                        top -= 10;

                        if (top <= target) {
                            clearInterval(anim);
                        };
                    },1);
                };
            } else {
                window.location.href = `/#${name}`
            }

        });
    });
}
