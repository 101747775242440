import Swiper from 'swiper';

export const offerSlider = () => {

    let offerBtn = document.querySelectorAll('[am-btn="offer"]');
    offerBtn.forEach( (item) => {
        item.addEventListener('click', () => {
            let tabBtn = document.querySelectorAll('[data-tab]'),
                tabBlock = document.querySelectorAll('[data-tablist]'),
                tabBtnCurrent = document.querySelector(`[data-tab=${item.dataset.metal}]`),
                tabBlockCurrent = document.querySelector(`[data-tablist=${item.dataset.metal}]`);

            tabBtn.forEach( (btn) => {
                btn.classList.remove('is-active');
            });
            tabBtnCurrent.classList.add('is-active');

            tabBlock.forEach( (block) => {
                block.classList.remove('is-active');
            });
            tabBlockCurrent.classList.add('is-active');
        });
    });



    if (window.innerWidth < 671) {
        let offerSlider = new Swiper('.offer-slider', {
            containerModifierClass: 'offer-slider',
            wrapperClass: 'offer-slider__wrapper',
            slideClass: 'offer-slide',
            slideActiveClass: 'offer-slide__active',
            slidesPerView: 'auto',
            width: 183,
            spaceBetween: 10,
            navigation:  {
                nextEl: '.offer-slider__next',
                prevEl: '.offer-slider__prev',
            },
            breakpoints: {
                319: {
                    slidesOffsetAfter: -90,
                },
                399: {
                    slidesOffsetAfter: -120,
                },
                479: {
                    slidesOffsetAfter: -183,
                }
            }
        });

        const hideNavi = () => {
            let btnNext = document.querySelector('.offer-slider__next'),
                btnPrev = document.querySelector('.offer-slider__prev'),
                translate = offerSlider.translate;

            if (btnNext) {
                if (offerSlider.isBeginning) {
                    btnPrev.classList.remove('is-visible');
                    btnNext.classList.add('is-visible');
                } else if (offerSlider.isEnd || translate < -192) {
                    btnPrev.classList.add('is-visible');
                    btnNext.classList.remove('is-visible');
                } else {
                    btnPrev.classList.add('is-visible');
                    btnNext.classList.add('is-visible');
                }
            }
        }

        hideNavi();
        offerSlider.on('slideChange', hideNavi);
    }

}
