export const modal = () => {
    const closeModal = (elem) => {
        elem.addEventListener('click', (event) => {
            if (event.target.classList.contains('js-modalClose')) {
                elem.classList.remove('is-active');
                document.body.style.overflow = '';
                document.documentElement.style.overflow = '';
            }
        });
    };

    let modalBtn = document.querySelectorAll('[data-open]');
    modalBtn.forEach( (item) => {
        item.addEventListener('click', () => {
            let name = item.dataset.open,
                modal = document.querySelector(`[data-modal="${name}"]`),
                submit = document.querySelector(`[data-submit]`),
                modalClose = modal.querySelectorAll('[data-close="modal"]');

            modal.classList.add('is-active');
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';

            closeModal(modal);

            submit.addEventListener('click', (event) => {
                let phoneInput = document.querySelector('[data-phone]'),
                    nameInput = document.querySelector('[data-name]'),
                    comment = document.querySelector('[name="comment"]'),
                    errorMessage = document.querySelector('[am-error="modal"]');

                const changeInput = (elem) => {
                    elem.addEventListener('input', () => {
                        elem.style.border = '';
                        errorMessage.classList.remove('is-active');
                    })
                };

                changeInput(phoneInput);
                changeInput(nameInput);

                if (nameInput.value.length == 0 && phoneInput.value === "+7 (___)___-____") {
                    errorMessage.textContent = 'Пожалуйста, корректно заполнитя поле: имя и телефон.'
                    errorMessage.classList.add('is-active');
                    phoneInput.style.border = "1px solid #B82800";
                    nameInput.style.border = "1px solid #B82800";

                } else if (nameInput.value.length == 0) {
                    errorMessage.textContent = 'Пожалуйста, корректно заполните поле: имя.'
                    errorMessage.classList.add('is-active');
                    nameInput.style.border = "1px solid #B82800";

                } else if (phoneInput.value === "+7 (___)___-____") {
                    errorMessage.textContent = 'Пожалуйста, корректно заполните поле: телефон.'
                    errorMessage.classList.add('is-active');
                    phoneInput.style.border = "1px solid #B82800";

                } else {
                    let error = false;

                    // отправка формы
                    fetch('/ajax_feedback', {
                        method: 'POST',
                        body: new FormData(document.querySelector('[am-form="modal"]'))
                    })
                    .then(res => {
                        if (res.status !== 200) {
                            return Promise.reject(`статус ответа: ${res.status}`)
                        }

                        return Promise.resolve(res)
                    })
                    // .then(res => res.json())
                    .catch(err => {
                        error = true
                        console.log(`Ошибка отправки заявки - ${err}`);
                    })
                    .then(() => {
                        if (!error) {
                            modal.classList.remove('is-active');
                            modal = document.querySelector(`[data-modal="thx"]`);
                            modal.classList.add('is-active');
                            phoneInput.value = "+7 (___)___-____";
                            nameInput.value = '';
                            comment.value = '';
                            closeModal(modal);
                            error = false;

                            if (typeof ym == 'function') {
                                ym(73900726,'reachGoal','book');
                            }

                            if (typeof gtag == 'function') {
                                gtag('event', 'click', {
                                    event_category: 'Book',
                                });
                            }
                        } else {
                            errorMessage.textContent = 'В настоящий момент на сервере технические неполадки, попробуйте позже.'
                            errorMessage.classList.add('is-active');
                        }
                    });
                }
            });
        });
    });

// Маска инпута
    function setCursorPosition(pos, elem) {
        elem.focus();
        if (elem.setSelectionRange) elem.setSelectionRange(pos, pos);
        else if (elem.createTextRange) {
            let range = elem.createTextRange();
            range.collapse(true);
            range.moveEnd("character", pos);
            range.moveStart("character", pos);
            range.select()
        }
    }

    function mask(event) {
        let matrix = this.defaultValue,
            i = 0,
            def = matrix.replace(/\D/g, ""),
            val = this.value.replace(/\D/g, "");
            def.length >= val.length && (val = def);
        matrix = matrix.replace(/[_\d]/g, function(a) {
            return val.charAt(i++) || "_"
        });

        this.value = matrix;
        i = matrix.lastIndexOf(val.substr(-1));
        i < matrix.length && matrix != this.defaultValue ? i++ : i = matrix.indexOf("_");
        setCursorPosition(i, this)
    }

    let input = document.querySelector("[data-phone]");
    input.addEventListener("input", mask, false);

}
